
import { defineComponent, computed } from "vue";
import { i18n } from "@/i18n";

export default defineComponent({
  name: "ScientificBrochures",
  setup() {
    const { t } = i18n.global;

    const list = computed(() => [
      {
        title: t("scientificBrochures.articles.a.title"),
        file: t("scientificBrochures.articles.a.file"),
        imageUrl: t("scientificBrochures.articles.a.imageUrl")
      },
      {
        title: t("scientificBrochures.articles.b.title"),
        file: t("scientificBrochures.articles.b.file"),
        imageUrl: t("scientificBrochures.articles.b.imageUrl")
      }
    ]);

    const thumbClick = (idx: number) => {
      const item = list.value[idx];
      const imageRepo =
        "https://api.metacriacoes.com/friesland-campina/app-friso/v1/?action=getFile&type=doc&file=scientific-brochures/";
      const fileUrl = imageRepo + item["file"];
      window.open(fileUrl);
    };

    return {
      t,
      list,
      thumbClick
    };
  }
});
