import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e424588e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bg-light h-full" }
const _hoisted_2 = { class: "container py-8" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "grid grid-cols-1 md:grid-cols-2 gap-4" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "text-primary font-display font-bold text-5xl text-center mb-4",
        innerHTML: _ctx.t('scientificBrochures.title')
      }, null, 8, _hoisted_3),
      _createElementVNode("div", {
        class: "text-primary text-center mb-8",
        innerHTML: _ctx.t('scientificBrochures.textA')
      }, null, 8, _hoisted_4),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, idx) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "thumbnail h-56 rounded-xl p-4 bg-cover bg-center flex flex-col justify-end cursor-pointer",
            key: `thumbnail-${idx}`,
            style: _normalizeStyle({ backgroundImage: `url('${item.imageUrl}')` }),
            onClick: ($event: any) => (_ctx.thumbClick(idx))
          }, [
            _createElementVNode("div", {
              class: "font-display text-2xl text-white font-bold title",
              innerHTML: item.title
            }, null, 8, _hoisted_7)
          ], 12, _hoisted_6))
        }), 128))
      ])
    ])
  ]))
}